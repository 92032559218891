<template>
    <v-dialog :persistent="restoringSnapshot" v-model="restoreDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" icon :color="isRestoreToCurrentInstance ? 'primary' : ''">
                        <v-icon v-if="isRestoreToCurrentInstance">restore</v-icon>
                        <v-icon v-else>group</v-icon>
                    </v-btn>
                </template>
                <span v-if="isRestoreToCurrentInstance">Restore to the current state of this instance</span>
                <span v-else>Restore to the current state of a target instance</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <v-icon>restore</v-icon>
                <span class="ml-1 secondary--text text-uppercase">snapshot restore</span>
                <v-spacer></v-spacer>
                <v-btn :disabled="restoringSnapshot" @click="restoreDialog = false" icon><v-icon>clear</v-icon></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-banner v-if="isColdSnapshot" class="mt-3">
                    <v-avatar slot="icon" color="white" size="30"><v-icon color="info">info</v-icon></v-avatar>
                    This snapshot is in cold storage, it might take longer than usual to restore its contents.
                </v-banner>
                <div v-if="snapshotData">
                    <div class="mt-5">
                        <div>
                            <span>Snapshot name</span>
                            :
                            <span class="font-weight-bold">{{ snapshotData.long_id }}</span>
                        </div>
                        <div>
                            <span>Snapshot timestamp</span>
                            :
                            <span class="font-weight-bold">{{ snapshotData.snapshot_timestamp | dateTimeToHuman }}</span>
                        </div>
                    </div>
                    <v-alert text prominent type="info" class="mt-4">
                        <v-row align="center">
                            <v-col class="grow">
                                <div class="font-weight-bold">Did you know?</div>
                                <div>You can restore partially too.</div>
                            </v-col>
                            <v-col class="shrink">
                                <v-btn
                                    href="https://docs.nuvolos.cloud/features/snapshots/restore-a-snapshot#partially-restore-a-snapshot"
                                    target="_blank"
                                    outlined
                                    small
                                    color="info">
                                    Learn more
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                    <div v-if="isRestoreToCurrentInstance">
                        <v-alert outlined color="error" prominent type="warning" class="mt-5">
                            <span class="font-weight-bold">Here is what happens when you restore a snapshot to the current state</span>
                            <ul class="subtitle-2 mt-3">
                                <li>
                                    The
                                    <span class="text-decoration-underline font-weight-bold">current state</span>
                                    of this instance will be be overwritten with the contents of the snapshot
                                    <span class="text-decoration-underline font-weight-bold">{{ snapshotData.long_id }}</span>
                                    .
                                </li>
                                <li class="subtitle-2">
                                    An auto snapshot of the
                                    <span class="text-decoration-underline font-weight-bold">current state</span>
                                    of this instance will be created (to be able to revert this operation).
                                </li>
                            </ul>
                            <v-divider class="my-4 secondary" style="opacity: 0.22"></v-divider>
                            <v-checkbox class="secondary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                        </v-alert>
                    </div>
                    <div v-else>
                        <h3 class="mt-5">Target Instance</h3>
                        <v-select
                            :items="instanceNames"
                            v-model="selectedInstance"
                            label="Select target instance to overwrite"
                            :disabled="restoring || !sourceSnapshotName"
                            outlined></v-select>
                        <v-alert v-if="selectedInstance" outlined color="error" prominent type="warning" class="mt-5">
                            <span class="font-weight-bold">Here is what happens when overwrite the current state of a target instance</span>
                            <ul class="mt-3">
                                <li>
                                    <span class="subtitle-2">
                                        The contents (files, tables, and application) of the current state of the instance
                                        <span class="text-decoration-underline font-weight-bold">{{ selectedInstance }}</span>
                                        will be cleared and replaced with the contents of
                                        <span class="text-decoration-underline font-weight-bold">{{ sourceSnapshotName }}.</span>
                                    </span>
                                </li>
                                <li class="subtitle-2">
                                    A restorable auto snapshot of the current state of
                                    <span class="text-decoration-underline font-weight-bold">{{ selectedInstance }}</span>
                                    will be created (to be able to revert this operation).
                                </li>
                            </ul>
                            <v-divider class="my-4 secondary" style="opacity: 0.22"></v-divider>
                            <v-checkbox class="secondary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                        </v-alert>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <div class="d-flex flex-column justify-center align-center w-100">
                    <v-tooltip v-if="isRestoreToCurrentInstance && !isInstanceArchived" top>
                        <template v-slot:activator="{ on }">
                            <v-checkbox
                                class="ma-0 pa-0"
                                v-on="on"
                                v-model="createBackupSnapshot"
                                label="Create a backup snapshot of the current state before restoring"></v-checkbox>
                        </template>
                        <span>This option is recommended in order to create backup and allow for restoring old objects in the future.</span>
                    </v-tooltip>
                    <div class="d-flex align-center justify-end mt-5 w-100">
                        <v-btn color="primary" @click="reviewSnapshot" text :disabled="restoringSnapshot || restoring">review snapshot</v-btn>
                        <v-btn
                            v-if="isRestoreToCurrentInstance"
                            text
                            class="mr-1"
                            color="error"
                            :loading="restoringSnapshot"
                            :disabled="restoringSnapshot || !agreeBox"
                            @click="restoreSnapshotToCurrentState">
                            Restore
                        </v-btn>
                        <v-btn
                            v-else
                            color="error"
                            class="mr-1"
                            text
                            :loading="restoring"
                            @click="overwriteCurrentState"
                            :disabled="restoring || !selectedInstance.length || !agreeBox">
                            overwrite
                        </v-btn>
                    </div>
                    <v-alert :value="error" color="error" prominent type="error" class="mt-5" text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Snapshot Restoration Error</span>
                            An error has occurred and the snapshot could not be restored. Please try again later and if the problem persists contact
                            support@alphacruncher.com
                        </div>
                    </v-alert>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isEditorRole, isMasterOrDistributedInstance } from '@/utils'

export default {
    name: 'SnapshotRestoreDialog',
    props: {
        snapshotData: Object,
        isColdSnapshot: Boolean,
        isRestoreToCurrentInstance: Boolean
    },
    data() {
        return {
            error: false,
            createBackupSnapshot: true,
            agreeBox: false,
            restoring: false,
            selectedInstance: '',
            restoreDialog: false
        }
    },
    methods: {
        restoreSnapshotToCurrentState: function () {
            const fetchBody = { snid: this.$props.snapshotData.snid }
            if (this.isInstanceArchived) {
                fetchBody.createBackup = false
            } else {
                fetchBody.createBackup = this.createBackupSnapshot
            }
            this.$store.dispatch('snapshotStore/restoreSnapshotToCurrentState', fetchBody).finally(() => {
                this.restoreDialog = false
            })
        },
        overwriteCurrentState: function () {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Overwriting target, please wait....',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.error = false
            this.restoring = true
            const iid = this.spaceInstances.find(instance => instance.long_id.toUpperCase() === this.selectedInstance.toUpperCase()).iid
            this.$axios
                .put(`snapshots/${this.$props.snapshotData.snid}/load_to/${iid}/async`, {})
                .then(response => {
                    this.restoreDialog = false
                    this.$store.dispatch('showGlobalDialog', {
                        dialogText: 'Loading of snapshot into instance has started, see the status below',
                        dialogTitle: 'Loading snapshot into existing instance',
                        dialogAction: ['dismiss'],
                        taskId: response.data.tkid
                    })
                })
                .catch(() => {
                    this.error = true
                })
                .finally(() => {
                    this.restoring = false
                })
        },
        reviewSnapshot: function () {
            this.$router.push({
                name: 'snapshot-overview',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$props.snapshotData.snid
                }
            })
        }
    },
    computed: {
        ...mapState('snapshotStore', ['restoringSnapshot']),
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapGetters('instanceStore', ['isInstanceArchived']),
        sourceSnapshotName: function () {
            return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$props.snapshotData.snid)
        },
        instanceNames: function () {
            const editorInstances = this.spaceInstances.filter(instance => isEditorRole(instance.role))
            const instances = editorInstances.map(instance => instance.long_id)
            return instances.filter(instance => !isMasterOrDistributedInstance(instance))
        }
    },
    watch: {
        restoreDialog: function (nextVal) {
            if (!nextVal && this.error) {
                this.error = false
            }
        }
    }
}
</script>
